//DEV
const dev_baseURL = 'https://iqos.cgmdev.org/';

//STAGING
const stag_baseURL = 'https://services-stag-vs.iqositalia.it/';

// //PROD
const prod_baseURL = 'https://services-vs.iqositalia.it/';

export const PRODUCTION = true;

const env = () => {
	if (PRODUCTION) {
		return { baseURL: prod_baseURL };
	}
	return { baseURL: stag_baseURL };
};

export default env;
